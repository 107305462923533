.gallery {
	position: relative; // Ustawienie pozycji względnej dla pseudo-elementu
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 0;
	overflow: hidden; // Ukrycie nadmiarowych elementów, jeśli występują

	// Pseudo-element ::before dla tła
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url("../img/BCRtlogaleria.jpg"); // Tło galerii
		background-size: cover;
		background-position: center;
		filter: brightness(0.7); // Zmniejszenie jasności
		z-index: -1; // Pseudo-element poniżej zawartości
		box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.5); // Cień na dole sekcji
	}

	.main-display {
		width: 100%;
		max-width: 800px;
		margin-bottom: 20px;
		margin-top: 20px;
		position: relative;
		transition: opacity 0.3s ease-in-out; // Ustaw animację dla opacity
		opacity: 1; // Domyślna przezroczystość
		&.fade-out {
			opacity: 0; // Ustaw przezroczystość na 0 podczas fade-out
		}
		img {
			width: 100%;
			height: 400px; // Możesz dostosować wysokość
			object-fit: cover;
			transition: opacity 0.3s ease-in-out; // Ustaw animację dla obrazów
		}
		iframe,
		.main-image {
			width: 100%;
			height: 400px;
			object-fit: cover;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); // Standardowy cień
			transition: box-shadow 0.3s ease-in-out; // Płynna zmiana cienia przy zmianie

			// Efekt glow na aktualnie wyświetlanym elemencie
			&.active {
				iframe,
				.main-image {
					box-shadow: 0px 0px 25px 10px rgba(0, 123, 255, 0.6); // Glow efekt
					animation: pulse 2s infinite ease-in-out; // Pulsująca animacja
				}
			}

			// Animacja glow
			@keyframes pulse {
				0% {
					box-shadow: 0px 0px 25px 10px rgba(0, 123, 255, 0.6);
				}
				50% {
					box-shadow: 0px 0px 30px 15px rgba(0, 123, 255, 0.4);
				}
				100% {
					box-shadow: 0px 0px 25px 10px rgba(0, 123, 255, 0.6);
				}
			}
		}
	}

	.thumbnail-carousel {
		display: flex;
		overflow-x: auto;
		gap: 10px;
		padding: 10px;
		max-width: 800px;
		scroll-behavior: smooth;

		&::-webkit-scrollbar {
			height: 8px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #00b0b8;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-track {
			background-color: #f1f1f1;
			border-radius: 10px;
		}

		.thumbnail {
			min-width: 100px;
			height: 100px;
			flex: none;
			cursor: pointer;
			transition: transform 0.3s ease-in-out;

			&:hover {
				transform: scale(1.1);
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&.active-thumbnail {
				border: 3px solid #00b0b8; // Aktywna miniatura z ramką
				box-shadow: 0px 0px 15px rgba(0, 123, 255, 0.5); // Cień dla aktywnej miniatury
				animation: thumbnailPulse 2s infinite ease-in-out; // Pulsacja dla aktywnej miniatury
			}
		}

		// Animacja glow dla aktywnej miniatury
		@keyframes thumbnailPulse {
			0% {
				box-shadow: 0px 0px 15px rgba(0, 123, 255, 0.5);
			}
			50% {
				box-shadow: 0px 0px 20px rgba(0, 123, 255, 0.3);
			}
			100% {
				box-shadow: 0px 0px 15px rgba(0, 123, 255, 0.5);
			}
		}
	}

	@media (max-width: 768px) {
		.main-display {
			iframe,
			.main-image {
				height: 300px;
			}
		}

		.thumbnail-carousel {
			max-width: 90%;
			gap: 5px;
		}

		.thumbnail {
			min-width: 80px;
			height: 80px;
		}
	}

	@media (max-width: 480px) {
		.main-display {
			iframe,
			.main-image {
				height: 250px;
			}
		}

		.thumbnail-carousel {
			max-width: 100%;
			padding: 5px;
		}

		.thumbnail {
			min-width: 60px;
			height: 60px;
		}
	}
}
