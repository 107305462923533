// Stylizacja głównego kontenera modalu
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); // Przyciemnione tło
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  // Stylizacja zawartości modalu
  .modal-content {
    background: #ffffff;
    border-radius: 12px; // Zaokrąglone rogi modalu
    width: 900px;
    height: 600px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); // Cień wokół modalu
    overflow: hidden;
  }
  
  // Lewa sekcja (Logo i tło)
  .modal-left {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #6a11cb, #2575fc); // Gradient jako tło
    color: #fff;
    padding: 20px;
  
    .logo {
      width: 100%;
    
    }
  
    .background {
      width: 100%;
      height: 100%;
    }
  }
  
  // Prawa sekcja (Formularz kontaktowy)
  .modal-right {
    width: 60%;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    h2 {
      font-size: 1.8em;
      margin-bottom: 20px;
      color: #333;
    }
  
    // Stylizacja każdego etapu formularza
    .step1, .step2 {
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      label {
        font-size: 1.1em;
        color: #444;
      }
  
      select,
      input[type="email"],
      textarea {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        font-size: 1em;
        width: 100%;
        box-sizing: border-box;
        transition: border 0.3s ease-in-out;
  
        &:focus {
          border: 1px solid #2575fc;
          outline: none;
        }
      }
  
      select {
        background: #f9f9f9;
      }
  
      textarea {
        resize: none; // Wyłączenie możliwości zmiany rozmiaru
        height: 80px;
      }
  
      .custom-message {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  
    // Stylizacja błędów walidacji
    .error-message {
      color: #e74c3c;
      font-size: 0.9em;
      margin-top: -10px;
    }
  
    // Stylizacja przycisków formularza
    button {
      background: #2575fc;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 12px 18px;
      font-size: 1.1em;
      cursor: pointer;
      transition: background 0.3s;
  
      &:hover {
        background: #1b63d0;
      }
  
      &:disabled {
        background: #cccccc;
        cursor: not-allowed;
      }
    }
  
    // Stylizacja przycisku zamknięcia
    .close-btn {
      background: transparent;
      color: #e74c3c;
      font-size: 1.1em;
      margin-top: 20px;
      cursor: pointer;
      border: none;
      align-self: flex-end;
  
      &:hover {
        color: #c0392b;
      }
    }
  }
  
  // Stylizacja kontenera dla pól wyboru problemu
  .custom-message {
    margin-top: 15px;
  }
  
  // Stylizacja kontenera dla błędów walidacji
  .error-message {
    margin-top: -10px;
  }
  