.map-contact-container {
	background-image: url("../img/BCRmap1.JPG");
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #000; // Czarny kolor tła
	color: white; // Kolor tekstu
	padding: 20px;
	height: 50vh;
}

.map-contact-map-container {
	width: 50%;
	height: 100%;
	max-width: 50%;
	border-radius: 15px; // Zaokrąglone rogi
	overflow: hidden;
	margin-bottom: 20px;
	z-index: 1000;
}

.map-contact-contact-container {
	height: 300px;
	text-align: center;
	padding: 0px 20px;
	margin-left: 5%;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 15px; // Zaokrąglone rogi dla tekstu
	z-index: 1000;
}

.map-contact-contact-info {
	margin-bottom: 20px;
}

.map-contact-hours {
	font-size: 1.4rem;
}

.map-shadow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 1;
}

// Media queries for larger screens (Desktop)
@media (max-width: 768px) {
	.map-contact-container {
		flex-direction: column; /* Ułożenie w kolumnę w mobilnej wersji */
		align-items: center;
		padding: 10px; /* Zmniejszenie paddingu */
	}

	.map-contact-map-container {
		width: 100%;
		height: 350px; /* Zwiększenie wysokości mapy */
		margin-bottom: 15px;
	}

	.map-contact-contact-container {
		width: 100%;
		padding: 15px;
		font-size: 0.9rem; /* Zmniejszenie rozmiaru tekstu */
	}

	.map-contact-hours {
		font-size: 1rem; /* Zmniejszenie rozmiaru tekstu godzin otwarcia */
	}
}
