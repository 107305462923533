.trainer-card-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 20px;
	width: 100%;
	min-height: calc(100vh - 100px);
	margin-bottom: 100px;
  
	.trainer-card-details {
	  width: 90%;
	  max-width: 1200px;
	  margin: 20px;
	  background: #fff;
	  border-radius: 15px;
	  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	  overflow: hidden;
	  position: relative;
	  padding: 20px;
	  text-align: center;
	  opacity: 0;
	  transform: translateY(50px); // Initial state for animation
	  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  
	  // Animation for when the card becomes visible
	  &.visible {
		opacity: 1; 
		transform: translateY(0); // Animation when visible
		animation: fadeInSlideUp 0.8s ease-out forwards; // Ensure fade in animation is added
	  }
  	&.pop {
		transform: scale(0.9);
		transition: transform 0.6s ease-out;

		&.visible {
			transform: scale(1);
		}
	  .trainer-card-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -2; // Background behind content
		background-size: cover;
		background-position: center;
		filter: blur(5px) brightness(50%);
	  }
  
	  .trainer-title {
		font-size: 3rem; // Large title on desktop
		font-weight: bold;
		margin-bottom: 20px;
		text-transform: uppercase;
		color: #fff;
		transition: font-size 0.3s ease; // Smooth resizing
  
		@media (max-width: 1200px) {
		  font-size: 2.5rem; // Medium title for medium screens
		}
  
		@media (max-width: 900px) {
		  font-size: 2rem; // Smaller title on tablet
		}
  
		@media (max-width: 600px) {
		  font-size: 1.8rem; // Smallest title on mobile
		}
	  }
  
	  .trainer-card-content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		z-index: 1; // Ensure this is above the background
  
		@media (max-width: 900px) {
		  flex-direction: column;
		  align-items: center;
		}
  
		.trainer-card-image {
		  width: 100%;
		  max-width: 450px;
		  height: auto;
		  border-radius: 50%;
		  overflow: hidden;
		  flex-shrink: 0;
		  margin-right: 20px;
		  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
		  transition: transform 0.3s ease; // Smooth transition for zoom effect
  
		  @media (max-width: 900px) {
			width: 80%;
			max-width: 300px; // Resize image for tablet
			margin-bottom: 20px;
		  }
  
		  img {
			width: 100%;
			height: 100%;
			object-fit: cover;
  
			&:hover {
			  transform: scale(1.05); // Zoom in the image
			}
		  }
		}
  
		.trainer-card-info {
		  flex-grow: 1;
		  padding: 20px;
		  text-align: center;
		  opacity: 1; // Ensure text starts visible
  
		  @media (max-width: 900px) {
			padding: 0;
		  }
  
		  .trainer-name {
			font-size: 3.2rem;
			font-weight: bold;
			margin-bottom: 10px;
			font-family: "Hyperwave one", cursive;
			color: #00b0b8;
  
			@media (max-width: 900px) {
			  font-size: 1.8rem; // Resize on tablet
			}
  
			@media (max-width: 600px) {
			  font-size: 1.6rem; // Resize for mobile
			}
		  }
  
		  .trainer-card-text {
			p {
			  font-size: 1rem;
			  line-height: 1.6;
			  margin: 0;
			  font-weight: bold;
			  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
			  color: #fff;
			  text-align: justify;
  
			  @media (max-width: 900px) {
				font-size: 0.9rem; // Adjust text size on smaller screens
			  }
  
			  @media (max-width: 600px) {
				font-size: 0.85rem; // Adjust for mobile
			  }
			}
		  }
		}
	  }
	}
  
	@media (max-width: 900px) {
	  .trainer-card-details {
		.trainer-card-content {
		  flex-direction: column;
		  align-items: center;
  
		  .trainer-card-image {
			width: 80%;
			max-width: 300px;
			margin-bottom: 20px;
		  }
  
		  .trainer-card-info {
			padding: 0;
  
			.trainer-name {
			  font-size: 1.5rem;
			  text-align: center;
			}
  
			.trainer-card-text {
			  text-align: center;
			}
		  }
		}
	  }
	}
  
	@media (max-width: 600px) {
	  .trainer-card-details {
		padding: 10px; // Reduced padding on mobile for better fit
		margin: 10px; // Less margin on mobile
  
		.trainer-card-content {
		  .trainer-card-info {
			.trainer-name {
			  font-size: 1.4rem;
			}
  
			.trainer-card-text p {
			  font-size: 0.85rem;
			}
		  }
		}
	  }
	}
  }
  
  @keyframes fadeInSlideUp {
	0% {
	  opacity: 0;
	  transform: translateY(50px);
	}
	100% {
	  opacity: 1;
	  transform: translateY(0);
	}
  }}