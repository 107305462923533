.message-icon {
	position: fixed;
	bottom: 20px; // Odległość od dolnej krawędzi
	right: 20px; // Odległość od prawej krawędzi
	z-index: 1000; // Zapewnia, że ikona jest na wierzchu

	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px; // Szerokość kontenera
	height: 80px; // Wysokość kontenera
	background-color: #007bff; // Tło kontenera
	border-radius: 20%; // Zaokrąglenie rogów kontenera
	transition: background-color 0.3s ease, transform 0.3s ease; // Animacja zmiany koloru i powiększania

	&:hover {
		background-color: #fff; // Zmiana tła na biały podczas hover
		transform: scale(1.1); // Powiększanie kontenera o 10%
	}

	i {
		font-size: 30px; // Rozmiar ikony
		width: 80px; // Szerokość ikony
		height: 60px; // Wysokość ikony
		background-color: #fff; // Kolor tła ikony
		color: #007bff; // Kolor ikony
		border-radius: 10%; // Zaokrąglenie rogów ikony
		display: flex;
		align-items: center;
		justify-content: center;
		transition: color 0.3s ease, font-size 0.3s ease; // Animacje zmiany koloru i rozmiaru ikony

		&:hover {
			color: #007bff; // Kolor ikony podczas hover
			font-size: 40px; // Zwiększenie rozmiaru ikony podczas hover
		}
	}
}
