@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Dancing+Script:wght@400&family=Hyperwave+One&display=swap");

// General Styling
.section-title {
	text-align: center;
	text-transform: uppercase;
}

.studio-title {
	transform: skewY(-10deg);
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 40px;

	.highlight {
		font-family: "Hyperwave One", cursive;
		font-size: 100px;
		color: #00a4a6;
		margin-bottom: -30px;
		font-weight: bold;
	}

	.subtitle {
		font-family: "Hyperwave One", cursive;
		font-size: 45px;
		color: #000;
		font-style: italic;
		font-weight: bold;
	}
}

.underline {
	width: 60px;
	height: 4px;
	background-color: #00a4a6;
	margin: 0 auto 30px;
}

.aboutus {
	height: 85vh;

	i {
		font-size: 40px;
		margin-top: 20px;
		color: #0a17d5;
	}

	.aboutus-card {
		padding-top: 10%;

		.aboutus-card-title {
			justify-content: center;
			font-size: 80px;
			font-family: "Hyperwave One", cursive;
			color: #00b0b8;
			font-weight: bold;
		}

		.aboutus-card-text {
			font-weight: 600;
			text-align: justify; // Justyfikacja tekstu
			max-width: 800px; // Maksymalna szerokość tekstu
			margin: 0 auto; // Wyśrodkowanie kontenera
			padding: 0 15px; // Padding boczny dla lepszej czytelności
		}
	}

	.col-12,
	.col-md-6 {
		border-radius: 20px;
		padding: 20px;
	}

	.left,
	.right {
		opacity: 0;
		transition: transform 1s ease-out, opacity 1s ease-out;

		&.animate {
			animation: slideInLeft 1s ease-out forwards,
				slideInRight 1s ease-out forwards;
		}
	}

	.left {
		transform: translateX(-100%);
	}

	.right {
		transform: translateX(100%);
	}

	.image-container {
		display: flex;
		justify-content: center;
		overflow: visible;
		gap: 30px;

		.skew-container-one,
		.skew-container-two {
			width: 300px;
			height: 400px;
			position: relative;
			clip-path: polygon(0 11%, 100% 0%, 100% 79%, 0 91%);
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

			.image {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	@keyframes slideInLeft {
		0% {
			transform: translateX(-100%);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}

	@keyframes slideInRight {
		0% {
			transform: translateX(100%);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}

	// Responsive adjustments for mobile screens
	@media (max-width: 767px) {
		.left,
		.right {
			transform: translateX(0); // Disable animation on smaller screens
		}

		.image-container {
			display: none; // Hide image containers on mobile screens
		}
	}

	// Tablet and larger screens
	@media (min-width: 768px) {
		.left.animate {
			animation: slideInLeft 1s ease-out forwards;
		}

		.right.animate {
			animation: slideInRight 1s ease-out forwards;
		}
	}
}
