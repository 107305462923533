.info-banner {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	background-color: #f0f0f0;

	.content-wrapper {
		width: 90%;
		max-width: 1200px;
		height: 300px;
		background-size: cover;
		background-position: center;
		border-radius: 8px;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;

		&.slide-in {
			animation: slideIn 1s forwards;
		}

		&.slide-out {
			animation: slideOut 1s forwards;
		}
	}

	.banner-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		text-align: center;
		padding: 20px;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 8px;
		position: relative;

		.team-join {
			font-size: 18px;
			color: #fff;
			margin-bottom: -10px;
			text-transform: uppercase;
			letter-spacing: 2px;
		}

		.highlight {
			letter-spacing: 10px;
			font-size: 65px;
			text-transform: uppercase;
			font-weight: bold;
			animation: glow 1.5s ease-in-out infinite alternate;
		}

		.subtitle {
			font-size: 24px;
			margin-top: -10px;
			font-weight: bold;
		}

		.contact-info {
			position: absolute;
			bottom: 20px;
			left: 20px;

			padding: 10px 15px;
			border-radius: 8px;
			color: #f8f8f8;

			h4 {
				font-size: 18px;
				margin-bottom: 5px;
				text-transform: uppercase;
				text-decoration: solid;
				text-align: left;
			}

			.number {
				display: block;
				font-size: 20px;
				color: white;
				background-color: #00b0b8;
				margin: 5px 0;
				text-decoration: none;
				border: 1px solid #00b0b8;
				padding: 5px;
				border-radius: 5px;
				text-align: center;
				transition: transform 0.3s, background-color 0.3s;

				&:hover {
					transform: scale(1.05);
					background-color: #007bff;
				}
			}
			.email-link {
				text-decoration: none; // Usuń podkreślenie
				color: white; // Ustaw kolor tekstu na biały
				cursor: pointer; // Zachowaj wskaźnik "ręki" dla klikalności
				font-weight: normal; // Opcjonalnie, jeśli chcesz zmienić wagę tekstu

				&:hover {
					color: #ccc; // Opcjonalnie: możesz dodać efekt hover (np. jaśniejszy odcień)
				}
			}
		}

		.logo {
			position: absolute;
			bottom: 10px;
			right: 10px;
			width: 80px;
			height: auto;
			opacity: 0.8;

			img {
				max-width: 100%;
				height: auto;
				border-radius: 5px;
			}
		}

		// Strzałki po lewej stronie
	
	}
}

@keyframes slideIn {
	from {
		transform: translateX(100%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideOut {
	from {
		transform: translateX(0);
		opacity: 1;
	}
	to {
		transform: translateX(-100%);
		opacity: 0;
	}
}

@keyframes glow {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #00b0b8, 0 0 30px #00b0b8;
	}
	to {
		text-shadow: 0 0 20px #00b0b8, 0 0 30px #00b0b8, 0 0 40px #00b0b8;
	}
}

@media (max-width: 768px) {
	.content-wrapper {
		height: auto;
	}

	.banner-content {
		.highlight {
			font-size: 32px;
		}

		.subtitle {
			font-size: 18px;
		}

		.contact-info {
			bottom: 10px;
			left: 10px;
			padding: 5px 10px;
		}

		.logo {
			width: 60px;
			bottom: 5px;
			right: 5px;
		}
	}
}
