.carousel-container-one {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  height: 100vh;
  background-image: url("../img/bcrt.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: wheat;
  z-index: 0;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #666;
  padding: 3rem 0;

  .trainers-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  }
}

.carousel-track {
  display: flex;
  width: 100%;
  transition: transform 1s ease; // smoother animation
}

.carousel-slide 
  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; // Ustal szerokość na 100% kontenera
    flex-shrink: 0; // Zapobiega skurczaniu się slajdu
    height: 100%;
  }
  


.trainer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: #666;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: transform 0.5s ease;
  cursor: pointer;
}

.trainer-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.trainer-info {
  background-color: #666;
  color: white;
  padding: 1rem;
}

.trainer-name-instagram {
  display: flex;
  align-items: center; // Wyrównanie w pionie

  i {
    font-size: 20px;
    color: #00b0b8;
    padding-left: 10px; // Dostosuj w razie potrzeby
  }

  h4 {
    font-size: 1rem;
    margin: 0; // Usunięcie domyślnych marginesów
    display: flex; // Możliwość dalszej manipulacji wewnętrzną zawartością
    align-items: center; // Wyrównanie tekstu w pionie
  }
}

.prev-arrow,
.next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 3rem; // increase arrow size
  color: #00b0b8; // turquoise color for arrows
  cursor: pointer;
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background-color: #00b0b8;
}

// Responsive Layout
@media (max-width: 768px) {
  .carousel-slide {
    min-width: 100%; // 1 slide on small screens
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .carousel-slide {
    min-width: 50%; // 2 slides on medium screens
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1025px) {
  .carousel-slide {
    min-width: 25%; // 4 slides on large screens
    display: flex;
    justify-content: center;
  }
}
