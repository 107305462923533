.footer {
	background-color: #fff; // Białe tło stopki
	padding: 20px;

	.footer-main {
		display: flex;
		gap: 20px; // Przerwa między boxami
		margin-bottom: 20px;

		.footer-box {
			border-radius: 15px; // Zaokrąglone rogi
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.footer-left-box {
			background-image: url("../img/BCRfooter.jpg"); // Niebieskie tło
			background-size: cover;
			background-position: center;
			flex: 1; // Mniejszy box
			display: flex;
			align-items: center;
			justify-content: center;

			.company-logo {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.footer-right-box {
			background-color: #f0f0f0;
			flex: 2; // Większy box
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.footer-contact {
				align-self: flex-start; // Wyrównuje do lewej
				text-align: left; // Wyrównuje tekst do lewej

				h3 {
					margin-bottom: 5px; // Odstęp pod nagłówkiem
				}

				p {
					margin: 0; // Usunięcie domyślnych marginesów
				}
			}
			.footer-copyright {
				margin-top: 20px;
				p {
					display: inline-block;
					margin: 0;
				}

				a {
					margin-left: 8px;

					i {
						font-size: 24px; // Powiększa ikonę
						vertical-align: middle; // Wyrównuje ikonę z tekstem
					}
				}
			}

			.sponsor-logos {
				display: flex;
				flex-wrap: wrap; // Zawijanie logotypów
				justify-content: space-between; // Równomierne rozmieszczenie
				gap: 8px; // Przerwa między logotypami
				margin-bottom: 20px;
				width: 100%; // Pełna szerokość boxu

				.circle-logo-container {
					width: 100px; // Szerokość kontenera
					height: 100px; // Wysokość kontenera
					border-radius: 50%; // Nadaje kształt koła kontenerowi
					overflow: hidden; // Ukrywa zawartość wychodzącą poza kontener
					background-color: #fff; // Tło kontenera (na wypadek przezroczystych obrazów)
					display: flex;
					align-items: center;
					justify-content: center;

					.circle-logo {
						width: 100%; // Dopasowuje logo do kontenera
						height: 100%; // Dopasowuje logo do kontenera
						object-fit: contain; // Upewnia się, że całe logo jest widoczne
					}
				}
			}
		}
	}

	.footer-social {
		display: flex;
		justify-content: center;
		gap: 20px; // Przerwa między dolnymi boxami
		margin-top: 20px;

		.social-box {
			background-color: #f0f0f0;
			border-radius: 15px; // Zaokrąglone rogi
			padding: 10px;
			flex: 1; // Równomierny rozkład boxów na szerokości
			max-width: 50vw; // Maksymalna szerokość dla każdego boxu
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 30px;
				height: 30px;
			}
		}
		i {
			font-size: 5rem;
			color: black;
			transition: transform 0.3s, color 0.3s;
			&:hover{
				transform: scale(1.1);
			}
		}
	}

	@media (max-width: 768px) {
		.footer-main {
			flex-direction: column; // Kolumna
			gap: 10px; // Przerwa między boxami
		}

		.footer-left-box,
		.footer-right-box {
			width: 100%; // Pełna szerokość
			flex: 1; // Proporcjonalne zmniejszanie
		}

		.sponsor-logos {
			flex-direction: column; // Pionowy układ logotypów
			align-items: center; // Wyśrodkowanie logotypów
			.circle-logo-container {
				width: 80vw; // Szerokość kontenera zależna od szerokości ekranu
				height: auto; // Automatyczna wysokość
				margin-bottom: 10px; // Margines dolny między logotypami
			}

			.circle-logo {
				width: 80%; // Dopasowuje logo do kontenera
				height: auto; // Automatyczna wysokość, aby zachować proporcje
			}
		}

		.footer-social {
			flex-direction: column;
			gap: 15px;
			.social-box {
				max-width: 100vw;
			}
		}
	}
}
.footer-legal {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 15px;
	font-size: 14px;
  
	a {
	  color: #1ae7ea;
	  text-decoration: none;
	  font-weight: bold;
	  transition: color 0.3s ease-in-out;
  
	  &:hover {
		color: red;
		text-decoration: underline;
	  }
	}
  }
  