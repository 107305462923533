// CookieConsent.scss
.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 15px 30px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    z-index: 1000;
    text-align: center;
  
    .cookie-message {
      p {
        margin: 0 0 10px;
      }
  
      .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
  
        button {
          background-color: #4caf50;
          color: #fff;
          border: none;
          padding: 8px 12px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: #45a049;
          }
        }
      }
    }
  
    .cookie-settings {
      h3 {
        margin-bottom: 10px;
      }
  
      label {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
      }
  
      .buttons {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-top: 10px;
  
        button {
          background-color: #4caf50;
          color: #fff;
          border: none;
          padding: 8px 12px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: #45a049;
          }
  
          &:nth-child(2) {
            background-color: #f44336;
  
            &:hover {
              background-color: #e53935;
            }
          }
        }
      }
    }
  }
  