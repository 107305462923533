header.hero-img {
	position: relative;
	height: 100vh;
	background-image: url("../img/bcr.jpg");
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	color: wheat;
	z-index: -2;
	clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
	font-family: "Open Sans", sans-serif; /* Dodanie czcionki Open Sans */
	display: flex;
	justify-content: center; /* Wycentrowanie poziome */
	align-items: flex-start; /* Przesunięcie zawartości do góry */
	padding-bottom: 25vh; /* Dodanie odstępu od góry, dostosuj w razie potrzeby */

	.hero-shadow {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: -5;
	}

	.hero-text {
		padding-top: 20vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 100%;
	}

	.company-logo {
		padding-top: -50px;
		width: 80vw; /* Zwiększenie logo do 80% szerokości */
		max-width: 800px; /* Zwiększenie maksymalnej szerokości logo */
		margin-bottom: 20px;
		transform: translateX(0); /* Brak przesunięcia na małych ekranach */
	}

	.subtext {
		max-width: 1200px;
		font-family: "Open Sans", sans-serif; /* Ustawienie czcionki Open Sans */
		font-size: 1.4vw; /* Zwiększenie rozmiaru tekstu dla subtextu */
		color: #ffffff;
		margin-top: 10px;
		padding: 0 2vh;
		text-align: center; /* Wyśrodkowanie tekstu */
		transform: translateX(0); /* Brak przesunięcia na małych ekranach */
	}
}

@media (max-width: 768px) {
	header.hero-img {
	
	.subtext {
		font-size: 3vw; /* Zmniejszenie rozmiaru tekstu na większych ekranach */
		padding: 0 2vh;
	}
}}

@media (min-width: 1200px) {
	header.hero-img {
	.company-logo {
		transform: translateX(-20%); /* Przesunięcie w lewo dla ekranów powyżej 1200px */
	
	
	}

	.subtext {
		transform: translateX(-15%); /* Przesunięcie w lewo dla ekranów powyżej 1200px */
		font-size: 1.4vw; /* Zmniejszenie rozmiaru tekstu */
		padding: 0 20px;
		
	}}
}
