.tshirt-customizer-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100vh;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url("../img/tlokoszulki.jpg");
		background-size: cover;
		background-position: center;
		filter: blur(2px) brightness(50%);
		z-index: -1;
	}
}

.component-header {
	font-size: 60px; /* Większy rozmiar czcionki */
	color: #fff;
	position: absolute;
	display: block;
	top: 10px;
	margin-bottom: 20px; /* Odstęp od reszty komponentu */
	text-align: center; /* Wyrównanie do środka */
	text-transform: uppercase;
	font-family: "Hyperwave One", sans-serif;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	color: #00a9b7;
	left: 38%;
}
.tshirt-preview-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.tshirt-preview {
	width: 550px;
	height: 550px;
	border: 2px solid transparent; /* Zmieniono na przezroczyste, bo nie wyświetlamy już samego div'a */
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 20px;
	color: #000;
}

/* Dodanie stylu dla obrazka koszulki */
.tshirt-image {
	width: 100%; /* Dopasowanie szerokości obrazka do kontenera */
	height: auto;

	display: block;

	margin-bottom: 20px;
}

.price-info {
	font-size: 70px;

	color: #ddd;
	font-family: "Hyperwave One", sans-serif;
}

.tshirt-options-container {
	margin-top: 10%;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
	color: #ddd;
}

.color-options,
.text-info {
	/* Zmieniono na .text-info zamiast .text-options */
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-transform: uppercase;
	h3 {
		color: white;
	}
}

.color-circle-container {
	display: flex;
	gap: 15px;
}

.color-circle {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	cursor: pointer;
	border: 2px solid transparent;
	transition: transform 0.2s ease-in-out;
}

.color-circle.white {
	background-color: #fff;
}
.color-circle.black {
	background-color: #000;
}
.color-circle.blue {
	background-color: #00a9b7;
}
.color-circle.red {
	background-color: #86061e;
}

.color-circle:hover,
.color-circle.active {
	transform: scale(1.2);
	border: 2px solid #000;
}

.size-info {
	h4 {
		margin-bottom: 10px;
		text-transform: uppercase;
	}

	.size-row {
		display: flex;
		align-items: center;
		gap: 10px; /* Odstęp między ikoną a tekstem */
		font-size: 18px;
		margin: 5px 0;
	}

	/* Styl dla ikon */
	.fa-person-dress,
	.fa-person {
		font-size: 30px; /* Ustal rozmiar ikon */
		color: #00b0b8; /* Dopasowanie koloru do motywu */
	}
}
.fa-solid {
	font-size: 4rem;
	cursor: pointer;

	&.active {
		color: red; // Zmiana koloru wybranego ludzika na czerwony
	}
}
.text-option {
	/* Zmieniono hover i usunięto interakcję z napisem */
	padding: 5px;
	font-weight: 800;
	border-radius: 4px;
	background: none; /* Domyślnie bez tła */
	transition: background 0.3s ease-in-out;
}
.slider-container {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
}

.slider-arrow {
	background: none;
	border: none;
	font-size: 40px;
	cursor: pointer;
	color: white;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
}

.slider-arrow.left {
	left: -50px;
}

.slider-arrow.right {
	right: -50px;
}
.slider-thumbnails {
	position: absolute;
	top: 80px; /* Ustawienie pod nagłówkiem */
	left: 50%;
	transform: translateX(-50%); /* Wyśrodkowanie */
	display: flex;
	gap: 10px;
}

.thumbnail {
	cursor: pointer;
	padding: 10px 15px;
	border: 2px solid white;
	color: white;
	font-size: 16px;
	font-weight: bold;
	transition: background 0.3s, color 0.3s;
	text-transform: uppercase;
	background: rgba(0, 0, 0, 0.5); /* Lekko przeźroczyste */
}

.thumbnail.active {
	background: white;
	color: black;
}

/* Dostosowanie dla małych ekranów */
@media (max-width: 768px) {
	.slider-thumbnails {
		top: 60px; /* Dostosowanie pozycji dla mobilnych */
		flex-direction: column; /* Na mobilkach w pionie */
		align-items: center;
	}
}

.text-option:hover {
	background: none; /* Usunięto efekt po najechaniu */
}

.text-option.selected {
	background: #ddd;
	color: #000;
}
@media (max-width: 768px) {
	/* Stylizacja dla małych ekranów */
	.tshirt-customizer-wrapper {
		flex-direction: column; /* Ustawienie układu pionowego */
		height: auto;

		.component-header {
			font-size: 40px; /* Zmniejszenie nagłówka */
			position: relative; /* Ustawienie w pozycji względnej */
			left: 0; /* Centrowanie */
			margin-bottom: 30px; /* Większy margines na mobilkach */
		}

		.tshirt-preview-container {
			width: 100%; /* Rozciągnięcie koszulki na pełną szerokość */
			margin-bottom: 20px; /* Większy odstęp na mobilnych */
		}

		.tshirt-image {
			padding-top: 30%;
			width: 250px; /* Zmniejszenie rozmiaru koszulki */
			height: auto;
			margin-bottom: 20px;
		}

		.price-info {
			font-size: 40px; /* Dostosowanie rozmiaru tekstu */
			margin-top: 10px; /* Mniejszy odstęp */
		}

		.tshirt-options-container {
			align-items: center; /* Wyrównanie opcji na środku */
		}

		.color-options {
			align-items: center;
			text-align: center;
		}

		.color-circle-container {
			justify-content: center; /* Wyśrodkowanie opcji kolorów */
		}

		.color-circle {
			width: 25px; /* Mniejszy rozmiar przycisków na mobilkach */
			height: 25px;
		}

		.size-info h4 {
			font-size: 18px; /* Mniejszy nagłówek */
		}

		.size-row {
			font-size: 16px; /* Mniejszy rozmiar tekstu */
		}

		.text-info h3 {
			font-size: 18px; /* Zmniejszenie nagłówka napisów */
		}

		.text-option {
			font-size: 16px; /* Dostosowanie wielkości tekstu */
		}
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 5px rgba(255, 0, 0, 0.3);
	}
	50% {
		transform: scale(1.1);
		box-shadow: 0 0 15px rgba(255, 0, 0, 0.6);
	}
	100% {
		transform: scale(1);
		box-shadow: 0 0 5px rgba(255, 0, 0, 0.3);
	}
}

@keyframes explosion {
	0% {
		opacity: 1;
		transform: translateY(0) scale(1);
	}
	100% {
		opacity: 0;
		transform: translateY(-50px) scale(1.5);
	}
}

.shirt-switcher {
	position: absolute;
	top: 10%;
	left: 48.5%;
	transform: translateX(-50%);
	margin-top: 8px;
	background: rgba(0, 0, 0, 0.4);
	padding: 10px;
	border-radius: 8px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	display: flex;
	gap: 15px;
	margin-bottom: 20px;

	button {
		padding: 12px 24px;
		border: none;
		cursor: pointer;
		font-size: 17px;
		font-weight: bold;
		border-radius: 30px;
		position: relative;
		overflow: hidden;
		transition: background 0.3s, transform 0.2s ease-in-out;

		&.valentino {
			background: linear-gradient(45deg, #ff4e50, #fc913a);
			color: white;
		}

		&.normal {
			background: linear-gradient(45deg, #12dceb, #47f1f7);
			color: white;
		}

		&:hover {
			animation: pulse 0.8s infinite;
		}

		&:active {
			transform: scale(0.95);
		}

		&.active {
			background: linear-gradient(45deg, #e73c7e, #ff4e50);
		}
	}

	/* Efekt wybuchu serduszek */
	.heart {
		position: absolute;
		font-size: 11px;
		color: red;
		opacity: 0;
		animation: explosion 0.6s linear;
	}

	/* Efekt unoszących się hantli */
	.dumbbell {
		position: absolute;
		font-size: 13px;
		color: black;
		opacity: 0;
		animation: explosion 0.6s linear;
	}
	@media (max-width: 768px) {
		.shirt-switcher {
			position: relative;
			flex-direction: row;
			padding: 5px;
		}
	
		button {
			width:50%;
			
			font-size: 8px;
			border-radius: 25px;
			margin-bottom: 5px; // Dodatkowa przestrzeń między przyciskami
		}
	}
}
@keyframes blink {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	50% {
		opacity: 0.3;
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.limited-stock {
	margin-top: 5px;
	font-size: 20px;
	font-weight: bold;
	color: red;
	text-transform: uppercase;
	text-align: center;
	animation: blink 1s infinite;
}

.buy-now-button {
	background: linear-gradient(45deg, #ff4e50, #fc913a);
	color: white;
	font-size: 18px;
	font-weight: bold;
	border: none;
	border-radius: 30px;
	padding: 12px 24px;
	cursor: pointer;
	transition: background 0.3s, transform 0.2s ease-in-out;
	margin-top: 5px;
}

.buy-now-button:hover {
	background: linear-gradient(45deg, #e73c7e, #ff4e50);
	transform: scale(1.05);
}

.buy-now-button:active {
	transform: scale(0.95);
}

/* Dostosowanie dla małych ekranów */
@media (max-width: 768px) {
	.buy-now-button {
		font-size: 14px;
		padding: 10px 20px;
		width: 100%;
	}
}
