.blog-section {
    text-align: center;
    padding: 20px;
}

.blog-header {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    background-image: url("../img/blog/laptop.jpg");
    background-size: cover;
    background-position: center;
}

.blog-logo {
    height: 200px;
    z-index: 1;
    padding-right: 30px;
    transition: height 0.3s;
}

.blog-title {
    font-family: "Hyperwave One", sans-serif;
    font-size: 40vh;
    margin: 0;
    z-index: 1;
    color: white;
    padding-left: 80px;
    transform: rotate(-20deg);
    transition: font-size 0.3s;
}

.article-section {
    min-height: 50vh;
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
}

.article-content {
    max-width: 60vw; /* Ustawienie domyślnej szerokości */
}

.article-description {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    white-space: pre-line;
    padding-bottom: 3rem;
}

.article-title-left {
    font-weight: bold;
    font-style: italic;
}

.blog-carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.carousel-arrow {
    background-color: rgba(128, 128, 128, 0.7);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 3rem;
    cursor: pointer;
    margin: 10px;
    padding: 10px 20px;
    transition: background-color 0.3s;
}

.carousel-arrow:hover {
    background-color: rgba(128, 128, 128, 0.9);
}

.blog-articles {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap; /* Ułatwia układ kafelków na mniejszych ekranach */
}

.article-slide {
    width: 350px;
    height: 350px;
    border-radius: 10px;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.article-slide.active {
    transform: scale(1.1);
    opacity: 1;
}

/* Animacja przejścia dla artykułu */
.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Stylizacja dla mniejszych ekranów */
@media (max-width: 1024px) {
    .article-slide {
        width: 45%;
    }
    .article-content {
        max-width: 70vw; /* Nadpisuje dla ekranów poniżej 1024px */
    }
}

@media (max-width: 768px) {
    .blog-header {
        flex-direction: column;
        align-items: center;
    }
    .blog-logo {
        height: 150px;
    }
    .blog-title {
        font-size: 20vh;
        transform: none;
        padding-left: 0;
    }
    .article-slide {
        width:200px;
        height:200px;
    }
    .carousel-arrow {
        font-size: 2rem;
    }
    .article-content {
        max-width: 90vw; /* Nadpisuje dla ekranów poniżej 768px */
    }
}

@media (max-width: 480px) {
    .blog-logo {
        height: 80px;
    }
    .blog-title {
        font-size: 10vh;
    }
    .article-content {
        max-width: 90vw; /* Dla bardzo małych ekranów */
    }
}
