// Przykładowy styl SCSS dla przezroczystego, ciemnego tła navbaru

.navbar {
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5); // Przezroczyste tło navbaru
    position: fixed;

    .navbar-toggler-icon {
        color: #00b0b8;
    }

    .nav-link:focus,
    .nav-link:hover,
    .navbar-nav .nav-link.active,
    .navbar-nav .show > .nav-link {
        color: #00b0b8;
    }

    a {
        color: white;
        font-size: 1.1rem;
    }

    img {
        height: 50px; // Ustawienie wysokości logo
        width: auto; // Automatyczna szerokość, aby zachować proporcje
        max-height: 100%; // Logo nie może przekraczać wysokości paska nawigacyjnego
    }

    .dropdown-menu {
        border: none; // Usunięcie obramowania
        background-color: rgba(0, 0, 0, 0.5); // Zmiana tła na ciemne przezroczyste
    }
}

@media (max-width: 768px) {
    .navbar {
        .dropdown-menu {
            border: none; // Usunięcie obramowania
            background-color: rgba(0, 0, 0, 0.5); // Zmiana tła na ciemne przezroczyste
        }
    }
}
