* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Ustawienie stylu podstawowego dla całego dokumentu */
body {
	font-family: "Open Sans", system-ui;
	color: #020202;
}
@font-face {
	font-family: 'Hyperwave One';
	src: url('./fonts/hyperwave-one.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  }
.wrapper {
	max-width: 1600px; /* Maksymalna szerokość kontenera */
	margin: 0 auto; /* Wyśrodkowanie kontenera */
	padding: 0 15px; /* Padding wewnętrzny, aby treść nie dotykała krawędzi */
	box-sizing: border-box; /* Uwzględnienie paddingu i bordera w szerokości */
}