

@media ( (max-width: 767px)) {



	.section-padding {
		padding: 6em 0;
	}
}
.section-padding {
	padding: 6em 2em;
}
.reviews{
    .carousel-container{
        justify-content: space-between;
    }
}
.review-box {
	position: relative;
	margin: 2em;
	padding: 2em;
	border-radius: 8px;
	box-shadow: 3px 3px 10px rgba(46, 46, 46, 0.18);
	transition: transform 0.3s;
	z-index: 2000;

	&:hover {
		transform: scale(0.95);
	}
	.reviews-box-img {
		position: absolute;
		top: 8%;
		left: 75%;
		transform: translate(-50%, -50%);
		img {
			width: 80px;
			height: 80px;
			object-fit: cover;
			border-radius: 50%;
		}
	}
	.reviews-box-quote {
		margin-bottom: 1em;
		font-size: 3rem;
		color: #00a4a6;
	}
	.reviews-box-text {
		margin-bottom: 1em;
		font-size: 1rem;
		color: black;
	}
}
