.services-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 20px;
	width: 100%;
	min-height: 100vh;
	background-color: #f5f5f5;
	z-index: 1;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url("../img/BcrCennik.jpg");
		background-size: cover;
		background-position: center;
		filter: blur(2px) brightness(50%);
		z-index: -1;
	}}

	.services-header {
		text-align: center;
		margin-bottom: 40px;
		transform: rotate(-5deg);

		h1 {
			font-size: 6rem;
			letter-spacing: 0.2rem;
			text-transform: uppercase;
			font-family: "Hyperwave One", sans-serif;
			color: #00bfff;
			margin-bottom: -2rem;
		}

		h3 {
			font-size: 3.5rem;
			color: #f9f9f9;
			font-family: "Mr Dafoe", cursive;
		}
	}

	.services-container {
		display: flex;
		justify-content: center;
		gap: 20px; // Odstępy między kafelkami
		.fizjoterapia {
			background-image: url("../img/Butons/3.jpg");
			background-size: cover;
			background-position: center;
		}
		.dietetyka {
			background-image: url("../img/Butons/2.jpg");
			background-size: cover;
			background-position: center;
		}
		.trening {
			background-image: url("../img/Butons/newfot.jpg");
			background-size: cover;
			background-position: center;
		}
		.amek {
			background-image: url("../img/trenerzy/Amadeusz1.jpg");
			background-size: cover;
			background-position: center;
		}
	}

	.service-tile {
		width: 250px;
		height: 250px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #333;
		color: #fff;
		border-radius: 10px;
		cursor: pointer;
		transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

		&:hover {
			background-color: #444;
			transform: scale(1.05);
		}

		.service-title {
			font-size: 1.2rem;
			text-align: center;
			font-weight: 800;
		}

		&.active {
			background-color: #555;
		}
	}

	.price-list-panel {
		position: fixed;
		top: 80px; // Dopasuj do swojego layoutu, aby panel zaczynał się poniżej nagłówka
		left: -350px; // Początkowa pozycja poza ekranem
		width: 300px;
		height: 90%;
		background-color: rgba(50, 50, 50, 0.7);
		color: #fff;
		padding: 20px;
		box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.3);
		border-radius: 0 10px 10px 0;
		transition: left 0.5s ease;

		&.show {
			left: 0; // Pozycja wysunięta
		}

		.price-list-content {
			h2 {
				text-align: center;
				margin-bottom: 20px;
				font-size: 1.5rem;
			}

			.service-item {
				display: flex;
				justify-content: space-between;
				padding: 8px 0;
				border-bottom: 1px solid #555;

				.price {
					font-weight: bold;
					font-size: 1rem;
					white-space: nowrap;
				}
			}
		}
	}

// Dostosowanie dla tabletów i mniejszych ekranów (max-width: 1024px)
@media (max-width: 1024px) {
	.services-container {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		gap: 15px;
	}

	.service-tile {
		width: 220px;
		height: 220px;
	}

	.services-header {
		margin-bottom: 20px;
		h1 {
			font-size: 4rem;
		}
		h3 {
			font-size: 2.5rem;
		}
	}

	.price-list-panel {
		width: 250px;
		left: -300px; // Zmniejsz początkową pozycję
		top: 60px; // Dopasowanie do mniejszych layoutów
	}
}

// Dostosowanie dla urządzeń mobilnych (max-width: 768px)
@media (max-width: 768px) {
	.services-container {
		flex-direction: column; // Kafelki układają się pionowo
		gap: 10px;
	}

	.service-tile {
		width: 90%; // Szerokość kafelka dostosowana do szerokości ekranu
		height: auto;
		min-height: 180px;
	}

	.services-header {
		margin-bottom: 10px;
		h1 {
			font-size: 3rem; // Zmniejszenie rozmiaru tekstu
		}
		h3 {
			font-size: 2rem;
		}
	}

	.price-list-panel {
		width: 100%; // Panel zajmuje całą szerokość ekranu
		height: 50%;
		left: -100%; // Panel wychodzi całkowicie poza ekran
		top: 50px; // Dostosowanie pozycji do mniejszych ekranów

		&.show {
			left: 0; // Wysunięcie panelu na ekran
		}
	}
}

// Dostosowanie dla bardzo małych urządzeń (max-width: 480px)
@media (max-width: 480px) {
	.services-container {
		gap: 5px; // Mniejsze odstępy między kafelkami
	}

	.service-tile {
		width: 100%;
		height: auto;
		padding: 10px; // Zmniejszenie paddingu
	}

	.services-header {
		h1 {
			font-size: 2.5rem;
		}
		h3 {
			font-size: 1.5rem;
		}
	}

	.price-list-panel {
		height: 60%; // Większa wysokość panelu cenowego
		padding: 10px; // Mniejsze paddingi
	}
}
